// 定数
module.exports = {
  SUPPORTED_LANGUAGE: ['zh'],
  DICT: {
    SYS_INDUSTRY: 'INDUSTRY', // 行业
    SYS_PRODUCT_TYPES: 'SYS_PRODUCT_CATEGORY', // 产品类别
    SYS_PROVINCE_CITY: 'PROVINCE_CITY' // 城市列表
  },
  STATUS_MAP: {
    SUBMITED: {
      key: 'SUBMITED',
      name: '待审核',
      type: 'info'
    },
    APPROVED: {
      key: 'APPROVED',
      name: '已审核',
      type: 'success'
    },
    REJECTED: {
      key: 'REJECTED',
      name: '未通过',
      type: 'danger'
    }
  }
}
