import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/common.scss'
import './assets/font/iconfont.css'
import store from './store'
import '@/styles/btn.scss'
import './permission' // permission control
import {
  resetForm,
  parseTime
} from '@/utils'
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm

// 自定义表格工具扩展
import Pagination from '@/components/Pagination'
import RightToolbar from '@/components/RightToolbar'
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
// import '@/styles/index.scss'
import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
