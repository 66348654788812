import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

/* Router Modules */
// eslint-disable-next-line no-unused-vars
import bizRouter from './modules/biz'
import userRouter from './modules/user'
import organizationRouter from './modules/organization'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    // component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/view/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/view/LogIn'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/view/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/view/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    // component: Layout,
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/view/Index'),
    // hidden: true,
    redirect: '/baseData/deliverManage',
    children: [
      {
        path: '/baseData/deliverManage',
        component: () => import('@/view/baseData/deliverManage'),
        meta: {
          title: '投递管理',
        },
      },
     {
        path: '/baseData/positionManage',
        component: () => import('@/view/baseData/positionManage'),
        meta: {
          title: '职位管理',
        },
      },{
        path: '/baseData/talentsManage',
        component: () => import('@/view/baseData/talentsManage'),
        meta: {
          title: '人才管理',
        },
      },{
        path: '/baseData/talentPool',
        component: () => import('@/view/baseData/talentPool'),
        meta: {
          title: '人才库',
        },
      },{
        path: '/baseData/systemSetup',
        component: () => import('@/view/baseData/systemSetup'),
        meta: {
          title: '设置',
        },
      }, {
        path: '/baseData/distributionSpaces',
        component: () => import('@/view/baseData/DistributionSpaces'),
        meta: {
          title: '配货车位管理',
        },
      }, {
        path: '/baseData/boardPosManage',
        component: () => import('@/view/baseData/BoardPosManage'),
        meta: {
          title: '板位管理',
        },
      }, {
        path: '/baseData/boothManage',
        component: () => import('@/view/baseData/BoothManage'),
        meta: {
          title: '摊位管理',
        },
      }, {
        path: '/baseData/corrosion',
        component: () => import('@/view/baseData/Corrosion'),
        meta: {
          title: '费用维护',
        },
      }, {
        path: '/baseData/ledgerSearch',
        component: () => import('@/view/baseData/LedgerSearch'),
        meta: {
          title: '台账管理',
        },
      }, {
        path: '/baseData/zoneManage',
        component: () => import('@/view/baseData/ZoneManage'),
        meta: {
          title: '配货区分区',
        },
      }, {
        path: '/baseData/storageLocationManage',
        component: () => import('@/view/baseData/StorageLocationManage'),
        meta: {
          title: '库位维护',
        },
      }, {
        path: '/baseData/carTypeManage',
        component: () => import('@/view/baseData/CarTypeManage'),
        meta: {
          title: '车型维护',
        },
      },{
        path: '/baseData/inventoryLimit',
        component: () => import('@/view/baseData/InventoryLimit'),
        meta: {
          title: '库存限制',
        },
      },{
        path: '/baseData/companyManage',
        component: () => import('@/view/baseData/CompanyManage'),
        meta: {
          title: '合作公司',
        },
      },
      {
        path: '/customerProcessing/billCheck',
        component: () => import('@/view/customerProcessing/BillCheck'),
        meta: {
          title: '账单查询',
        },
      },
      {
        path: '/customerProcessing/complaintsHandling',
        component: () => import('@/view/customerProcessing/ComplaintsHandling'),
        meta: {
          title: '账单申诉',
        },
      },
      {
        path: '/customerProcessing/allUsers',
        component: () => import('@/view/customerProcessing/AllUsers'),
        meta: {
          title: '所有注册用户',
        },
      },
      {
        path: '/customerProcessing/accountApproval',
        component: () => import('@/view/customerProcessing/AccountApproval'),
        meta: {
          title: '账户批准',
        },
      },
      {
        path: '/customerProcessing/viewAccount',
        component: () => import('@/view/customerProcessing/viewAccount'),
        meta: {
          title: '查看账户',
        },
      },
      {
        path: '/customerProcessing/performance',
        component: () => import('@/view/customerProcessing/performance'),
        meta: {
          title: '工人绩效',
        },
      },
      {
        path: '/customerProcessing/clearingCharges',
        component: () => import('@/view/customerProcessing/ClearingCharges'),
        meta: {
          title: '收费结算',
        },
      },
      {
        path: '/customerProcessing/growthAndVIPLevels',
        component: () => import('@/view/customerProcessing/GrowthAndVIPLevels'),
        meta: {
          title: '成长值/VIP等级',
        },
      },
      {
        path: '/customerProcessing/growthValueApproval',
        component: () => import('@/view/customerProcessing/GrowthValueApproval'),
        meta: {
          title: '交易证明成长值审批',
        },
      },
      {
        path: '/customerProcessing/oaApprove',
        component: () => import('@/view/customerProcessing/OaApprove'),
        meta: {
          title: 'OA审批',
        },
      },
      {
        path: '/customerProcessing/accountApprovalList',
        component: () => import('@/view/customerProcessing/AccountApprovalList'),
        meta: {
          title: 'OA审批列表',
        },
      },
      {
        path: '/customerProcessing/customerComplaint',
        component: () => import('@/view/customerProcessing/CustomerComplaint'),
        meta: {
          title: '客户投诉',
        },
      },
      {
        path: '/agencyAppointment/shipperAgent',
        component: () => import('@/view/agencyAppointment/ShipperAgent'),
        meta: {
          title: '货主代理',
        },
      },
      {
        path: '/agencyAppointment/buyersAgent',
        component: () => import('@/view/agencyAppointment/BuyersAgent'),
        meta: {
          title: '买家代理',
        },
      },
      {
        path: '/coldStorage/coldStorageManage',
        component: () => import('@/view/coldStorage/ColdStorageManage'),
        meta: {
          title: '库间管理',
        },
      },
      {
        path: '/coldStorage/forkliftAllot',
        component: () => import('@/view/coldStorage/ForkliftAllot'),
        meta: {
          title: '叉车库间分配',
        },
      },
      {
        path: '/taskManage/forkliftTask',
        component: () => import('@/view/taskManage/ForkliftTask'),
        meta: {
          title: '叉车任务',
        },
      },
      {
        path: '/taskManage/stevedoreTask',
        component: () => import('@/view/taskManage/StevedoreTask'),
        meta: {
          title: '装卸任务',
        },
      },
      {
        path: '/otherManage/otherParams',
        component: () => import('@/view/otherManage/OtherParams'),
        meta: {
          title: '其他参数',
        },
      },
      {
        path: '/otherManage/paramsManage',
        component: () => import('@/view/otherManage/ParamsManage'),
        meta: {
          title: '参数管理',
        },
      },
      {
        path: '/otherManage/testUse',
        component: () => import('@/view/otherManage/TestUse'),
        meta: {
          title: '测试参数',
        },
      },
      {
        path: '/statementManage/boothReservation',
        component: () => import('@/view/statementManage/BoothReservation'),
        meta: {
          title: '摊位预约列表',
        },
      },
      {
        path: '/statementManage/boardUse',
        component: () => import('@/view/statementManage/BoardUse'),
        meta: {
          title: '板位使用情况',
        },
      },
      {
        path: '/statementManage/workerStatus',
        component: () => import('@/view/statementManage/WorkerStatus'),
        meta: {
          title: '板位使用情况',
        },
      },
      {
        path: '/statementManage/inventory',
        component: () => import('@/view/statementManage/Inventory'),
        meta: {
          title: '库存状况',
        },
      },
      {
        path: '/statementManage/coldStoreOrder',
        component: () => import('@/view/statementManage/ColdStoreOrder'),
        meta: {
          title: '冷库预约',
        },
      },
      {
        path: '/statementManage/deliveryTruck',
        component: () => import('@/view/statementManage/DeliveryTruck'),
        meta: {
          title: '配货车预约',
        },
      },
      {
        path: '/statementManage/vehicleEntryAndExit',
        component: () => import('@/view/statementManage/VehicleEntryAndExit'),
        meta: {
          title: '车辆进出场记录',
        },
      },
      {
        path: '/statementManage/unloadingTruckList',
        component: () => import('@/view/statementManage/UnloadingTruckList'),
        meta: {
          title: '卸货车预约列表',
        },
      },
      {
        path: '/statementManage/vehicleAttendRecord',
        component: () => import('@/view/statementManage/VehicleAttendRecord'),
        meta: {
          title: '车辆到场记录',
        },
      },
      {
        path: '/statementManage/accountImport',
        component: () => import('@/view/statementManage/AccountImport'),
        meta: {
          title: '账户导入',
        },
      },
      {
        path: '/systemSetup',
        component: () => import('@/view/SystemSetup')
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  organizationRouter,
  userRouter,
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

