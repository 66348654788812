<template>
  <div id="app">
    <router-view
      v-loading="loadingCount > 0"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.4)"
      element-loading-custom-class="my-loading"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['loadingCount'])
  }
}
</script>
<style lang="scss">
.my-loading {
  z-index: 2100;
}
</style>
<style lang="scss">
.my-loading {
  z-index: 2100;
}
</style>
