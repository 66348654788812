import axios from 'axios'
import { MessageBox } from 'element-ui'
import store from '@/store'
import { getToken, getJti } from '@/utils/auth'

import { ElMessage } from '@/utils/elmessage'
const Message = new ElMessage()

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    if (store.getters.language) {
      config.headers['Accept-Language'] = store.getters.language
      config.headers['x-client-locale'] = store.getters.language
    }
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    config.headers['jti'] = getJti() // only for dev
    // 发请求则loading++
    store.dispatch('app/loadingCountIncrease')
    // console.log('🙇%cRequest %s %c %s %s %c %s %c %s',
    //   'color: green;', config.method,
    //   'color: red', 'URL:', config.url,
    //   'color: green', config.params ? 'params:' + JSON.stringify(config.params) : '',
    //   'color: green', config.data ? 'data:' + JSON.stringify(config.data) : '')
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    store.dispatch('app/loadingCountReduce')
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    store.dispatch('app/loadingCountReduce')
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      Message.error({
        message: res.mesg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 020002: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === '020002') {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      // 即时等于200也有code不为000000的服务返回错误
      if (res.code && res.code !== '000000' && res.code !== 20000) { // 2000为mock res
        Message.error({
          message: res.code + ': ' + res.mesg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.data  || res.mesg || res.errMessage || 'Error'))
      }
      // console.log('🚀%cResponse Code:%s %c %s %s %c %s %s',
      //   'color: orange', res.code,
      //   'color: red', 'URL:', response.config.url,
      //   'color: magenta', 'Data:', JSON.stringify(res))
      return res
    }
  },
  error => {
    store.dispatch('app/loadingCountReduce')
    console.log('Error URL:' + error.config.url, error) // for debug

    if (error.response && error.response.data === 'Invalid captcha') {
      Message.error({
        message: '验证码错误！',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }
    if (error.response && error.response.data && error.response.data.code === '040003') {
      // 前端对于400 无效授权 特殊处理提示内容
      Message.error({
        message: '错误的用户名或密码！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
    if (error.response && error.response.data && error.response.data.code === '020002') {
      // to re-login
      MessageBox.confirm('您已注销，您可以点击取消停留在此页面，或重新登录', '注销确认', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    }
    if (error.code === 'ECONNABORTED') {
      // 请求超时
      Message.error({
        message: '请求超时',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
    Message.error({
      message: error.response && error.response.data && error.response.data.mesg || error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
