// eslint-disable-next-line no-unused-vars
import Cookies from 'js-cookie'

const state = {
  loadingCount: 0
}

const mutations = {

  LOADINGCOUNT_INCREASE: (state) => {
    state.loadingCount = state.loadingCount + 1
  },
  LOADINGCOUNT_REDUCE: (state) => {
    state.loadingCount = state.loadingCount - 1
  }
}

const actions = {
  loadingCountIncrease({ commit }) {
    commit('LOADINGCOUNT_INCREASE')
  },
  loadingCountReduce({ commit }) {
    commit('LOADINGCOUNT_REDUCE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
