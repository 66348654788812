const getters = {
  loadingCount: state => state.app.loadingCount,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  groups: state => state.user.groups,
  templates: state => state.template.templates,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  // swaggerApi: state => state.api.swaggerApi,
  dict: state => state['organization/dict'],
  global: state => {
    return {
      roles: state['organization/role'].roles
    }
  }
}
export default getters
