import request from '@/utils/request'

// 查询文件列表
export function listFile(data) {
  return request({
    url: '/docfile-service/accountdoc/conditions',
    method: 'post',
    data
  })
}

// 新增文件
export function addFile(data) {
  return request({
    url: '/docfile-service/accountdoc/add',
    method: 'post',
    data: data
  })
}

// 删除文件
export function delFile(id) {
  return request({
    url: '/docfile-service/accountdoc/' + id,
    method: 'delete'
  })
}

// 下载文件
export function downloadFile(id) {
  return request({
    url: '/docfile-service/accountdoc/download/' + id,
    method: 'get',
    responseType: 'blob'
  })
}

// 清理废弃文件
export function clearFile() {
  return request({
    url: '/docfile-service/accountdoc/clear',
    method: 'post'
  })
}

// 下载文件-服务不同
export function download(id) {
  return request({
    url: '/site-mgr/doc/download/' + id,
    method: 'get',
    responseType: 'blob'
  })
}
