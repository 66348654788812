import request from '@/utils/request'

// 查询字典数据列表
export function listData(data) {
  return request({
    url: '/site-mgr/dict/value/tree',
    method: 'post',
    data
  })
}

// 根据字典编码查询字典数据信息
export function getDicts(dicCode) {
  return request({
    // url: '/site-mgr/dict/value/conditions',
    url: '/site-mgr/dict/value/tree',
    method: 'post',
    data: {
      dicCode
    }
  })
}

// 查询字典数据详细
export function getData(id) {
  return request({
    url: '/site-mgr/dict/value/' + id,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data) {
  return request({
    url: '/site-mgr/dict/value/add',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/site-mgr/dict/value/' + data.id,
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(id) {
  return request({
    url: '/site-mgr/dict/value/' + id,
    method: 'delete'
  })
}

// 导出字典数据
export function exportData(query) {
  return request({
    url: '/site-mgr/dict/value/export/',
    method: 'get',
    params: query
  })
}
