import request from '@/utils/request'

export function login(data) {
  const form = {
    username: data.username,
    password: data.password,
    captcha: data.authCode,
    captchaUuid: data.captchaUuid,
    grant_type: 'password',
    scope: 'read'
  }
  return request({
    url: '/authorization-server/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ='
    },
    method: 'post',
    params: form
  })
}

export function getInfo(loginName) {
  return request({
    url: '/organization/user',
    method: 'get',
    params: { uniqueId: loginName }
  })
}

export function logout() {
  return Promise.resolve({
    code: '000000',
    mesg: '成功',
    time: new Date()
  })
}

export function getMenuByUserID(id) {
  return request({
    url: '/organization/user/getMenuByUserID/' + id,
    method: 'get'
  })
}

export function checkExistUsername(username) {
  return request({
    url: '/organization/user/checkExistUsername',
    method: 'get',
    params: { username: username }
  })
}

export function checkExistMobile(mobile) {
  return request({
    url: '/organization/user/checkExistMobile',
    method: 'get',
    params: { mobile: mobile }
  })
}

export function forgetPassword(data) {
  return request({
    url: '/organization/user/forgetPassword',
    method: 'post',
    data
  })
}


export function getListPendingUser(data) {
  return request({
    url: '/organization/user/web/checkQuery',
    method: 'post',
    data
  })
}

export function userQuery(data) {
  return request({
    url: '/organization/user/web/userQuery',
    method: 'post',
    data
  })
}

export function conditions(data) {
  return request({
    url: '/organization/user/conditions',
    method: 'post',
    data
  })
}

export function readFile(data) {
  return request({
    url: '/organization/usersautoagree/readFile',
    method: 'post',
    data
  })
}

export function importQuery(data) {
  return request({
    url: '/organization/usersautoagree/web/importQuery',
    method: 'post',
    data
  })
}

export function approveUser(data) {
  return request({
    url: '/organization/user/web/check/'+data.id,
    method: 'put',
    data
  })
}

// 获取货主供应商账户信息
export function getUserOfBuyer(id) {
  return request({
    url: '/organization/user/web/getUserOfBuyer/'+ id,
    method: 'get'
  })
}

// 获取司机账户信息
export function getUserOfDriver(id) {
  return request({
    url: '/organization/user/web/getUserOfDriver/'+ id,
    method: 'get'
  })
}

// 获取叉车账户信息
export function getUserOfForklift(id) {
  return request({
    url: '/organization/user/web/getUserOfForklift/'+ id,
    method: 'get'
  })
}

// 获取装卸工账户信息
export function getUserOfStevedore(id) {
  return request({
    url: '/organization/user/web/getUserOfStevedore/'+ id,
    method: 'get'
  })
}
