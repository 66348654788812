import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, getUsername, setUsername, removeUsername, setJti, removeJti } from '@/utils/auth'
// import { formatMenuTree } from '@/utils/menu'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: getUsername(),
  avatar: '',
  introduction: '',
  roles: [],
  user_menu: [],
  userType: '',
  groups: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_MENU: (state, user_menu) => {
    state.user_menu = user_menu
  },
  SET_USER_TYPE: (state, userType) => {
    state.userType = userType
  },
  SET_ORGS: (state, groups) => {
    state.groups = groups
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, authCode, captchaUuid } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, authCode: authCode, captchaUuid: captchaUuid }).then(response => {
        console.log('response', response)
        commit('SET_TOKEN', response.access_token)
        commit('SET_NAME', username.trim())
        setToken(response.access_token)
        setUsername(username.trim())
        setJti(response.jti)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.name).then(response => {
        const { data } = response
        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { description, username, userType, groups, roleIds } = data

        // roles must be a non-empty array
        // if (!roleIds || roleIds.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!')
        // }
        // getMenuByUserID(id).then(response => {
        // commit('SET_USER_MENU', formatMenuTree(response.data))
        // }).catch(error => {
        //   reject(error)
        // })

        commit('SET_ROLES', roleIds)
        commit('SET_NAME', username)
        setUsername(username)
        commit('SET_INTRODUCTION', description)
        commit('SET_USER_TYPE', userType)
        commit('SET_ORGS', groups)
        data.roles = roleIds // 前端菜单，这里使用roleIds，以便匹配在route上加role权限
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  // eslint-disable-next-line no-unused-vars
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()
        removeUsername()
        removeJti()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        // dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  }

  // dynamically modify permissions
  // async changeRoles({ commit, dispatch }, role) {
  //   const token = role + '-token'

  //   commit('SET_TOKEN', token)
  //   setToken(token)

  //   const { roles } = await dispatch('getInfo')

  //   resetRouter()

  //   // generate accessible routes map based on roles
  //   const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //   // dynamically add accessible routes
  //   router.addRoutes(accessRoutes)

  //   // reset visited views and cached views
  //   dispatch('tagsView/delAllViews', null, { root: true })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
