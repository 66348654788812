import Cookies from 'js-cookie'

const TokenKey = 'User-Token'
const UsernameKey = 'User-Name'
const JtiKey = 'User-Jti'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUsername() {
  return Cookies.get(UsernameKey)
}

export function setUsername(username) {
  return Cookies.set(UsernameKey, username)
}

export function removeUsername() {
  return Cookies.remove(UsernameKey)
}

export function getJti() {
  return Cookies.get(JtiKey)
}

export function setJti(jti) {
  return Cookies.set(JtiKey, jti)
}

export function removeJti() {
  return Cookies.remove(JtiKey)
}
