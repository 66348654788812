/** When your routing table is too long, you can split it into small modules **/

// import Layout from '@/layout'

const userRouter = {
  path: '/user',
  // component: Layout,
  alwaysShow: true,
  redirect: '/user/profile',
  name: 'User',
  // hidden: true,
  meta: {
    title: '基本设置',
    icon: 'setting',
    // roles: ['admin', 'manager', 'reviewer', 'editor']
  },
  children: [
    // {
    //   path: 'profile',
    //   // redirect: '/profile/index',
    //   component: () => import('@/view/profile/index'),
    //   name: 'UserProfile',
    //   meta: {
    //     title: '个人设置',
    //     noCache: true
    //   }
    // },
    // {
    //   path: 'notification',
    //   component: () => import('@/view/notification/index'),
    //   name: 'Notification',
    //   meta: {
    //     title: '通知消息'
    //   }
    // }
  ]
}

export default userRouter
