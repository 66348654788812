/** When your routing table is too long, you can split it into small modules **/

// import Layout from '@/layout'

const organizationRouter = {
  path: '/organization',
  component: () => import('@/view/Index'),
  redirect: '/organization/user',
  alwaysShow: true,
  name: 'Organization',
  meta: {
    title: '系统管理',
    icon: 'setting',
    roles: ['101']
  },
  children: [
    {
      path: '/organization/user',
      component: () => import('@/view/organization/user'),
      name: 'UserManagement',
      meta: {
        title: '功能列表',
        icon: 'peoples',
        roles: ['101']
      }
    },
    {
      path: '/organization/user',
      component: () => import('@/view/organization/user'),
      name: 'UserManagement',
      meta: {
        title: '用户管理',
        icon: 'peoples',
        roles: ['101']
      }
    },
    {
      path: '/organization/role',
      component: () => import('@/view/organization/role'),
      name: 'Role',
      meta: {
        title: '角色管理',
        icon: 'role',
        roles: ['101']
      }
    },
    {
      path: '/organization/menu',
      component: () => import('@/view/organization/menu'),
      name: 'Role',
      meta: {
        title: '菜单管理',
        icon: 'role',
        roles: ['101']
      }
    },
    {
      path: '/organization/group',
      component: () => import('@/view/organization/group'),
      name: 'Group',
      meta: {
        title: '组织架构',
        icon: 'group',
        roles: ['101']
      }
    },
    {
      path: '/organization/dict-data',
      component: () => import('@/view/organization/dict'),
      name: 'Dict',
      meta: {
        title: '数据字典',
        icon: 'el-icon-notebook-1',
        roles: ['101']
      }
    },
    {
      path: '/organization/dict-catalog',
      component: () => import('@/view/organization/dict/catalog'),
      name: 'DictData',
      meta: {
        title: '分类字典',
        icon: 'el-icon-notebook-2',
        roles: ['101']
      }
    },
    // {
    //   path: '/organization/file',
    //   component: () => import('@/view/organization/save-file'),
    //   name: 'File',
    //   meta: {
    //     title: '文件管理',
    //     icon: 'el-icon-files',
    //     roles: ['101']
    //   }
    // },
    // {
    //   path: '/organization/swagger',
    //   component: () => import('@/view/devops/swagger'),
    //   name: 'swagger',
    //   hidden: process.env.NODE_ENV !== 'development',
    //   meta: {
    //     title: 'Swagger',
    //     icon: 'el-icon-s-management',
    //     roles: ['101']
    //   }
    // },
    {
      path: '/organization/gateway',
      component: () => import('@/view/organization/gateway'),
      name: 'Gateway',
      meta: {
        title: '路由管理',
        icon: 'guide',
        roles: ['101']
      }
    }
  ]
}

export default organizationRouter
