import CONSTANTS from '@/constants'
import {
  getDicts
} from '@/api/organization/dict/data'

const state = {
  // sys_user_sex: [], // 用户性别
}

// eslint-disable-next-line no-unused-vars
Object.entries(CONSTANTS.DICT).forEach(([key, value]) => {
  state[value] = []
})

const mutations = {
  SET_DICT_DATA: (state, data) => {
    state[data.type] = data.value
    // state[data.type] = data.value.map(item => {
    //   return {
    //     label: item.title,
    //     value: item.value
    //   }
    // })
  }
}

function removeEmptyChildren(list) {
  list && list.forEach(item => {
    if (item.children) {
      if (item.children.length === 0) {
        item.children = undefined
      } else {
        removeEmptyChildren(item.children)
      }
    }
  })
  return list
}

const actions = {
  async getDicData(context, diccodes) {
    for (let i = 0; i < diccodes.length; i++) {
      if (state[diccodes[i]] && state[diccodes[i]].length === 0) {
        getDicts(diccodes[i]).then(res => {
          context.commit('SET_DICT_DATA', { type: diccodes[i], value: removeEmptyChildren(res.data) })
        })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
